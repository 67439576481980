import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import Search from "../../Components/Search/Search";
import NoData from "../../Components/NoData/NoData";
import { Button } from "../../Components/Button/Button";
import HistoryCard from "../../Components/HistoryCard/HistoryCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./LiveTests.scss";
import ReportTable from "../../Components/ReportTable/ReportTable";
import VariantPerformance from "../../Components/VariantPerformance/VariantPerformance";
import SequentialResults from "../Dashboard/Components/SequentialResults/SequentialResults";
import ReportGraphs from "../../Components/ReportGraphs/ReportGraphs";
import { UserContext } from "../../../UserContext";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GETHISTORIES, GETTESTSV2 } from "../../../Graphql/Queries";
import Datasets from "../../Components/DataSets/Datasets";
import ExperimentFilters from "../../Components/ExperimentFilters/ExperimentFilters";
import moment from "moment";
import {
  GETBIGQUERYREPORT,
  REFETCHREPORTHISTORY,
} from "../../../Graphql/Mutations";
import ContentSkeleton from "../../Components/Skeletons/ContentSkeleton";
import toast from "react-hot-toast";
import { CURRENCYSYMBOLSMAP } from "../../../constants";

const initialState = {
  searchText: "",
};
function reducer(state, action) {
  switch (action.type) {
    case "searchText":
      return { ...state, searchText: action.payload };
    case "testID":
      return { ...state, testID: action.payload };
    case "dimensionFilterExpression":
      return { ...state, dimensionFilterExpression: action.payload };
    case "includeProducts":
      return { ...state, includeProducts: action.payload };
    case "interaDayTable":
      return { ...state, interaDayTable: action.payload };
    case "selectionRange":
      return { ...state, selectionRange: action.payload };
    case "historySelection":
      if (action.payload.type === "deletehistory") {
        return {
          ...state,
          historySelection: {
            type: "",
            historyId: "",
          },
        };
      }
      return { ...state, historySelection: action.payload };
    default:
      return "Unrecognized command";
  }
}

const splideOptions = {
  type: "slide",
  perPage: 6,
  pagination: false,
  // autoWidth: true,
  perMove: 1,
  gap: "1rem",
  breakpoints: {
    1600: {
      perPage: 5,
    },
    1400: {
      perPage: 4,
    },
    800: {
      perPage: 3,
    },
    400: {
      perPage: 1,
    },
  },
};
const historySplideOptions = {
  type: "slide",
  perPage: 3,
  pagination: false,
  perMove: 1,
  gap: "0.5rem",
  breakpoints: {
    1200: {
      perPage: 2,
    },
    900: {
      perPage: 2,
    },
    400: {
      perPage: 1,
    },
  },
};

export const LiveTests = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getbigqueryreport] = useMutation(GETBIGQUERYREPORT);
  const [viewId, setViewId] = useState(null);
  const [refetchreporthistory] = useMutation(REFETCHREPORTHISTORY);
  const [testState, dispatchTest] = useReducer(reducer, {
    testID: "",
  });
  const historySplideRef = useRef(null);
  const [testFilterState, dispatchTestFilter] = useReducer(reducer, {
    dimensionFilterExpression: [],
    metricFilterExpression: [],
    includeProducts: false,
    interaDayTable: false,
    selectionRange: {
      selection: {
        startDate: moment(new Date()).toDate(),
        endDate: moment(new Date()).add(7, "days").toDate(),
        key: "selection",
      },
    },
  });
  const [report, setReport] = useState(null);
  const [historyState, dispatchHistory] = useReducer(reducer, {
    historySelection: {
      type: "",
      historyId: "",
    },
  });
  const [dataset, setDataset] = useState(null);
  const { state: storeState, myProfile } = useContext(UserContext);
  const [gettests] = useLazyQuery(GETTESTSV2);
  const [gethistories] = useLazyQuery(GETHISTORIES);
  const [tests, setTests] = useState({
    loading: true,
  });
  const [histories, setHistories] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const {
          data: { getTestsV2 },
          loading,
        } = await gettests({
          fetchPolicy: "network-only",
          variables: {
            page: null,
            size: null,
            filters: [
              {
                status: "live",
              },
              {
                client: storeState.client,
              },
              {
                viewId: storeState.store,
              },
              {
                name: state.searchText !== "" ? state.searchText : null,
              },
            ],
          },
        });
        setTests({
          loading,
          ...getTestsV2,
        });
      } catch (error) {}
    })();
  }, [storeState, state, gettests]);

  const getReportsHistoryQuery = useCallback(async () => {
    if (!testState.testID || !viewId) return;
    try {
      const {
        data: { getCachedAnalyticsData },
        loading,
      } = await gethistories({
        fetchPolicy: "network-only",
        variables: {
          client: storeState.client,
          viewId: viewId,
          test: testState.testID,
          filters: [
            {
              onlyGA4: true,
            },
          ],
        },
      });
      setHistories({
        loading,
        getCachedAnalyticsData,
      });
    } catch (error) {}
  }, [gethistories, storeState.client, viewId, testState.testID]);

  const refetchReportHistoryQuery = useCallback(
    async (id) => {
      try {
        setReport({
          loading: true,
        });
        const {
          data: { refetchTemporaryReportBigQuery },
          loading,
        } = await refetchreporthistory({
          variables: {
            id,
            dateRange: {
              startDate: moment(
                testFilterState.selectionRange.selection?.startDate
              ).format("yyyyMMDD"),
              endDate: moment(
                testFilterState.selectionRange.selection?.endDate
              ).format("yyyyMMDD"),
            },
            otherFilters: {
              includeProducts: testFilterState.includeProducts,
              interaDayTable: testFilterState.interaDayTable,
            },
          },
        });
        setReport({
          loading,
          ...refetchTemporaryReportBigQuery,
        });
        await getReportsHistoryQuery();
      } catch (error) {
        setReport(null);
        toast.error(error.message);
      }
    },
    [refetchreporthistory, testFilterState, getReportsHistoryQuery]
  );

  useEffect(() => {
    getReportsHistoryQuery();
  }, [getReportsHistoryQuery]);

  const getReport = async () => {
    try {
      setReport({
        loading: true,
      });
      const {
        data: { getBigQueryData },
        loading,
      } = await getbigqueryreport({
        variables: {
          client: storeState.client,
          dateRange: {
            startDate: moment(
              testFilterState.selectionRange.selection?.startDate
            ).format("yyyyMMDD"),
            endDate: moment(
              testFilterState.selectionRange.selection?.endDate
            ).format("yyyyMMDD"),
          },
          codebaseTestId: testState.testID,
          filters: {
            dimensionFilters: testFilterState.dimensionFilterExpression,
            metricFilters: testFilterState.metricFilterExpression,
            includeProducts: testFilterState.includeProducts,
            interaDayTable: testFilterState.interaDayTable,
          },
          dataSetId: dataset,
        },
      });
      dispatchHistory({
        type: "historySelection",
        payload: {
          type: "refetch",
          historyId: getBigQueryData?.historyId,
        },
      });
      setReport({
        loading,
        ...getBigQueryData,
      });
      await getReportsHistoryQuery();
    } catch (error) {
      if (error.message === "No data found") {
        setReport(null);
        toast.error("No data found");
      }
    }
  };

  if (!storeState.platform || storeState.platform === "UA")
    return (
      <NoData
        title="
        Universal Analytics has been replaced by GA4. Please select another property for live reporting.
    "
        details="Universal Analytics was the previous generation of Analytics. 
  This was working for the property type for websites prior to October 14, 2020."
      />
    );

  if (tests.loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  return (
    <div className="insights section-pd">
      <h1>Live Reports </h1>
      <Search
        placeholder="Search the Test"
        dispatch={dispatch}
        bindOnClick={true}
      />
      <div className="tests-list section-pd-top">
        <Splide aria-label="Live Tests" options={splideOptions}>
          {tests?.tests?.map(({ name, _id, livedate, property }) => (
            <SplideSlide key={_id}>
              <Button
                key={_id}
                onClick={(e) => {
                  dispatchTest({ type: "testID", payload: _id });
                  dispatchTestFilter({
                    type: "selectionRange",
                    payload: {
                      selection: {
                        startDate: moment(livedate ?? new Date()).toDate(),
                        endDate: moment(new Date()).toDate(),
                        key: "selection",
                      },
                    },
                  });
                  setViewId(property?.value);
                  setReport(null);
                }}
                className={`smart-pill small ${
                  testState.testID === _id ? "active" : ""
                }`}
              >
                {name.replaceAll("ACC_", "")}
              </Button>
            </SplideSlide>
          ))}
        </Splide>
      </div>

      {testState?.testID !== "" && (
        <Datasets
          client={storeState.client}
          viewId={storeState.store}
          myProfile={myProfile}
          dataset={dataset}
          setDataset={setDataset}
          splideOptions={historySplideOptions}
        />
      )}

      {dataset && (
        <ExperimentFilters
          testFilterState={testFilterState}
          dispatchTestFilter={dispatchTestFilter}
          getReport={getReport}
          test={tests?.tests?.find(({ _id }) => _id === testState.testID)}
        />
      )}

      {histories && histories?.getCachedAnalyticsData.length > 0 && (
        <div className="history-lists-wrapper section-pd-top">
          <h1>History </h1>
          <div className="history-lists">
            <Splide
              ref={historySplideRef}
              aria-label="My Favorite Images"
              options={historySplideOptions}
            >
              {histories.getCachedAnalyticsData.map((data, index) => (
                <SplideSlide key={index}>
                  <HistoryCard
                    dispatch={dispatchHistory}
                    historySplideRef={historySplideRef}
                    state={historyState}
                    index={index}
                    key={index}
                    {...data}
                    setReport={setReport}
                    refetchReportHistoryQuery={refetchReportHistoryQuery}
                  />
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      )}

      {report?.loading && <ContentSkeleton />}

      {report && !report?.loading && (
        <>
          <div className="report-table-wrapper border-bottom section-pd-bottom">
            <ReportTable
              total={[report?.totalOfResults]}
              controlVariantData={report?.result}
            />
          </div>
          <VariantPerformance
            calculatedResults={report?.calculatedResults}
            currency={CURRENCYSYMBOLSMAP[tests?.other?.currencyCode]}
          />
          <ReportGraphs
            timeSeriesData={report?.timeSeriesData}
            probabilityOfWinner={report?.probabilityOfWinner}
          />
        </>
      )}

      <SequentialResults results={report?.sequentialABTestResult} />
      {/* <NoData
        title="No Data Available"
        details="We apologize, but there is currently no data to display. Please check back later as we continue to update our records. Thank you for your patience and understanding."
      /> */}
    </div>
  );
};
