import {
  ActiveTestIcon,
  AudienceInsightsIcon,
  CVRReportsIcon,
  DashboardIcon,
  DocumentsLinksIcon,
} from "../Icons";
import { CVRReport } from "../Views/Pages/CVRReport/CVRReport";
import { Dashboard } from "../Views/Pages/Dashboard/Dashboard";
import { DocumentsLinks } from "../Views/Pages/DocumentsLinks/DocumentsLinks";
import { Insights } from "../Views/Pages/Insights/Insights";
import { LiveTests } from "../Views/Pages/LiveTests/LiveTests";
import ManagePassword from "../Views/Pages/ManagePassword/ManagePassword";
import ReportDetails from "../Views/Pages/ReportDetails/ReportDetails";

export const AuthRoutes = [
  {
    sidebar: true,
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    sidebar: true,
    name: "Active Tests",
    icon: <ActiveTestIcon />,
    path: "/activetests",
    element: <LiveTests />,
  },
  {
    sidebar: true,
    name: "Audience Insights",
    icon: <AudienceInsightsIcon />,
    path: "/insights",
    element: <Insights />,
  },
  {
    sidebar: true,
    name: "Documents & Links",
    icon: <DocumentsLinksIcon />,
    path: "/documents",
    element: <DocumentsLinks />,
  },
  {
    sidebar: true,
    name: "CVR Reports",
    icon: <CVRReportsIcon />,
    path: "/cvrreports",
    element: <CVRReport />,
  },
  {
    sidebar: false,
    name: "Report Details", // "Home" is the name of the route, it can be anything, it's just for reference
    path: "/report/:id",
    element: <ReportDetails />,
  },
  {
    sidebar: false,
    name: "Change Password",
    path: "/manage-password",
    element: <ManagePassword />,
  },
];
