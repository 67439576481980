import React from "react";
import "./ReportCard.scss";
import { Button } from "../Button/Button";
import { DocumentsLinksIcon, ReportIcon } from "../../../Icons";
import { NavLink, useNavigate } from "react-router-dom";
import {
  checkAdditionalRevenue,
  checkConclusion,
  checkConversionRate,
  checkStringLocalStrings,
} from "../../../utilities";
import ContentSkeleton from "../Skeletons/ContentSkeleton";

export default function ReportCard({ type = "report", data = null, currency }) {
  const navigate = useNavigate();
  const TypeReportContent = ({
    _id,
    conclusion = "",
    segmentName = "",
    duration = "",
    reportvalues = [],
    test,
    currency,
  }) => (
    <>
      <div className="report-card-header">
        <h2> {test?.name}</h2>
      </div>
      {conclusion !== "" && (
        <div className="report-card-content flex flex-wrap justify-space-between default-column-gap">
          <div>
            <h4> Segment:</h4>
            <p> {segmentName}</p>
          </div>
          <div>
            <h4> Duration:</h4>
            <p> {duration} Days</p>
          </div>
          <div>
            <h4> Result:</h4>
            <p> {checkConclusion(conclusion)}</p>
            <p className="conversionRate">
              {reportvalues?.map(({ changeInConversionRate }, index) => (
                <React.Fragment key={index}>
                  {checkConversionRate(changeInConversionRate, "", "%")} <br />
                </React.Fragment>
              ))}
            </p>
          </div>

          <div
            style={
              reportvalues?.length > 0
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
          >
            <h4> Additional Revenue /month::</h4>
            <p>
              {" "}
              {reportvalues?.map(({ additionalRevenuePerMonth }, index) => (
                <React.Fragment key={index}>
                  {checkAdditionalRevenue(
                    additionalRevenuePerMonth,
                    true,
                    currency,
                    "",
                    true
                  )}{" "}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        </div>
      )}

      <div className="report-card-footer">
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (_id) {
              navigate(`/report/${_id}`);
            }
          }}
          text={`More Details`}
          className={`smart-button big uppercase full-width`}
          icon={<ReportIcon />}
        />
      </div>
    </>
  );
  const TypeInisghtContent = ({
    test,
    title = test?.name ?? "",
    conclusion = "",
    learnings = [],
    triggers = test?.trigger ?? [],
  }) => (
    <>
      <div className="report-card-header">
        <h2> {title}</h2>
      </div>
      <div className="report-card-content flex flex-column flex-wrap justify-space-between">
        <div>
          <h4> Result:</h4>
          <p> {checkConclusion(conclusion)}</p>
        </div>
        <div>
          <h4> Triggers:</h4>
          <p>{triggers?.map(({ name }) => name).join(", ")}</p>
        </div>
        <div
          className="learning"
          style={
            learnings.length > 0 ? { minHeight: "100px" } : { display: "none" }
          }
        >
          <h4>Learnings:</h4>
          <p>
            {learnings?.map((value, index) => (
              <React.Fragment key={index}>
                {value} <br />
              </React.Fragment>
            ))}
          </p>
        </div>
      </div>
    </>
  );

  const TypeLinkCard = ({ title = "", link = null }) => (
    <>
      <div className="report-card-header">
        <h2> {title}</h2>
      </div>
      <div
        className="report-card-content flex flex-wrap justify-center"
        style={{
          paddingBottom: "0px",
        }}
      >
        <NavLink to={link} target="_blank" referrerPolicy="no-refere">
          <DocumentsLinksIcon />
        </NavLink>
      </div>
    </>
  );

  const TypeVariantPerformance = ({
    title = "Title",
    value,
    prefix = "",
    postfix = "",
    formatColor,
    check = true,
  }) => (
    <>
      <div className="report-card-header">
        <h2> {title}</h2>
      </div>
      <div
        className="report-card-content flex flex-wrap justify-center"
        style={{
          paddingBottom: "0px",
        }}
      >
        {!check
          ? value
          : checkStringLocalStrings(value, prefix, postfix, formatColor)}
      </div>
    </>
  );

  if (!data) return <ContentSkeleton />;
  return (
    <div className="report-card full-width">
      {type === "report" ? (
        <TypeReportContent {...data} currency={currency} />
      ) : null}
      {type === "insight" ? <TypeInisghtContent {...data} /> : null}
      {type === "link" ? <TypeLinkCard {...data} /> : null}
      {type === "variantperformance" ? (
        <TypeVariantPerformance {...data} />
      ) : null}
      {type === "sq" ? <TypeVariantPerformance {...data} /> : null}
    </div>
  );
}
